import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "ee454b82-4ece-494e-bf47-363c5590f58f",
        authority: "https://login.microsoftonline.com/1b04ceb1-fca4-4a68-913a-f4afe74ccd3c/",
        redirectUri: "https://oauthtest.unbiasedsecurity.xyz",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read", "Mail.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

